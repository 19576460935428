import { ChakraProps, Flex, Text } from '@chakra-ui/react';
import { useBlog, useTranslation } from '../lib/hooks.context';
import { safariOnlyCss } from '../styles/theme';
import { NewsTeaser } from '../types';
import { TeaserAuthor } from './Author';

interface ArticleInfoProps {
  date?: string;
  site?: string;
  author?: NewsTeaser['author'];
}

const ArticleInfo = ({
  date,
  site,
  author,
  ...rest
}: ArticleInfoProps & ChakraProps) => {
  const blog = useBlog(false);
  const t = useTranslation();
  const blogName = site || blog || t('main_site');

  if (author) {
    return (
      <TeaserAuthor author={author} textStyle="xs">
        <>
          {date && (
            <Text as="time" mr="2">
              {date}
            </Text>
          )}
          {blogName}
        </>
      </TeaserAuthor>
    );
  }

  return (
    <Flex
      color="secondaryColor"
      as={Text}
      textStyle="xs"
      gridGap="2"
      sx={safariOnlyCss({ '> *': { mr: 2 } })}
      {...rest}
    >
      {date && <time>{date}</time>}
      {site || blog || t('main_site')}
    </Flex>
  );
};
export default ArticleInfo;
