import { Box, ChakraProps, Flex, Text } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { ReactNode } from 'react';
import { Author as AuthorProps, Blogger } from '../types';

const Image = dynamic(() => import('./Image'));

type Props = AuthorProps | Blogger;

const fallback: Omit<Blogger, keyof AuthorProps> = {
  image: null,
  roles: null,
};

const Author = ({
  first_name: name,
  last_name: surname,
  description,
  ...props
}: Props) => {
  if (!name && !surname) {
    return null;
  }

  const { image, roles } =
    typeof (props as Blogger).image !== 'undefined'
      ? (props as Blogger)
      : fallback;

  const content = (
    <>
      <Text mb={roles ? 0 : 1} fontWeight="500">
        {name && `${name} `}
        {surname}
      </Text>
      {roles && (
        <Text textStyle="sm" mb="2">
          {roles}
        </Text>
      )}
      {description && <Text textStyle="sm">{description}</Text>}
    </>
  );

  return image ? (
    <Flex layerStyle="sectionPadding" alignItems="center">
      <Image
        src={image.src}
        alt={image.alt || ''}
        width="60"
        height="60"
        containerProps={{ flexShrink: 0 }}
        circle
      />
      <Box ml={[3, null, 5]}>{content}</Box>
    </Flex>
  ) : (
    <Box layerStyle="sectionPadding">{content}</Box>
  );
};

export const TeaserAuthor = ({
  author: { first_name: name, last_name: surname, ...author },
  children,
  ...props
}: { children?: ReactNode; author: Props } & ChakraProps) => {
  if (!name && !surname) {
    return null;
  }

  const { image } =
    typeof (author as Blogger).image !== 'undefined'
      ? (author as Blogger)
      : fallback;

  const content = (
    <>
      <Text>
        {name && `${name} `}
        {surname}
      </Text>
      {children && (
        <Text textStyle="sm" color="secondaryColor">
          {children}
        </Text>
      )}
    </>
  );

  return image ? (
    <Flex alignItems="center" {...props}>
      <Image
        src={image.src}
        alt={image.alt || ''}
        width="40"
        height="40"
        containerProps={{ flexShrink: 0 }}
        circle
      />
      <Box ml={[2, null, 3]}>{content}</Box>
    </Flex>
  ) : (
    <Box {...props}>{content}</Box>
  );
};

export default Author;
